import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  Sensor,
  SensorNames, SensorPosition,
  useModifySensorMutation
} from "../../store/apis/sensorsApi";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

import {EditSensor} from "./EditSensor";
import {useState} from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";

export const SavedSensorRow = ({
                                 savedSensor,
                                 removeSensor,
                                 online
                               }: {
  savedSensor: Sensor;
  removeSensor: (id: number) => void;
  online: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const [modify, modifyResult] = useModifySensorMutation();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleEdit = (name: string, position: SensorPosition) => {
    const modifiedSensor = {...savedSensor, humanName: name, position};
    modify(modifiedSensor);
  };

  const renderConnected = () => {
    if (online)
      return (
        <Button
          style={{backgroundColor: "transparent", cursor: "default"}}
          size={"small"}
          color={"success"}
        >
          Yhdistetty
        </Button>
      );
    else return <></>;
  };

  const confirmRemove = (name: string | undefined, id: number | undefined) => {
    setConfirmOpen(true);
  };

  return (
    <TableRow>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>Varoitus</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Haluatko varmasti poistaa sensorin {savedSensor?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>Peru</Button>
          <Button onClick={() => {
            removeSensor(savedSensor.id || 0);
            setConfirmOpen(false);
          }}>Poista</Button>
        </DialogActions>
      </Dialog>
      <EditSensor
        open={open}
        handleClose={() => setOpen(false)}
        handleEdit={handleEdit}
        name={savedSensor.humanName}
        position={savedSensor.position}
        newSensor={false}
      />
      <TableCell>{savedSensor.humanName}</TableCell>
      <TableCell>
        {SensorNames[savedSensor.position]}
      </TableCell>
      <TableCell>{savedSensor.sensorId}</TableCell>
      <TableCell> {renderConnected()}</TableCell>
      <TableCell align="right">
        <Button
          disabled={online}
          style={{backgroundColor: "transparent"}}
          startIcon={
            <EditIcon
              onClick={() =>
                setOpen(true)
              }
              style={{cursor: "pointer", width: "1.5em", height: "1.5em"}}
            />
          }
        ></Button>
        <Button
          disabled={online}
          style={{backgroundColor: "transparent"}}
          startIcon={
            <DeleteForeverOutlinedIcon
              onClick={() =>
                confirmRemove(savedSensor.humanName, savedSensor.id)
              }
              style={{cursor: "pointer", width: "1.5em", height: "1.5em"}}
            />
          }
        ></Button>
      </TableCell>
    </TableRow>
  );
};
