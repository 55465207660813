import * as React from "react";
import { useState } from "react";
import { Card, CardActions, CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  ConnectedDevice,
  sensorState,
  sensorStateString
} from "../../store/features/connectedDeviceSlice";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import BlockIcon from "@mui/icons-material/Block";
import remoteGATTServerManager from "../utils/RemoteGATTServerManager";
import { DownloadBar } from "./DownloadBar";
import { SensorNames, SensorPosition } from "../../store/apis/sensorsApi";

export const RenderConnectedSensor = ({
  sensor,
  singleDownloadCb
}: {
  sensor: ConnectedDevice;
  singleDownloadCb: () => void;
}) => {
  const [downloading, setDownloading] = useState(false);

  const startDownload = async () => {
    if (sensor.armId) {
      setDownloading(true);
      await remoteGATTServerManager.downloadSingle(sensor.armId);
    }
  };

  const stopMeasurement = async () => {
    if (sensor.armId) {
      await remoteGATTServerManager.stopSingle(sensor.armId);
    }
  };

  const renderDownload = (sensor: ConnectedDevice) => {
    if (sensor.sensorState === sensorState.READY && !downloading) {
      return (
        <CardActions>
          <Button
            startIcon={<DownloadIcon />}
            size="small"
            onClick={startDownload}
          >
            Lataa
          </Button>
        </CardActions>
      );
    } else return <div />;
  };

  const renderDownloadProgress = (sensor: ConnectedDevice) => {
    if (sensor.sensorState === sensorState.SENDINGDATA || downloading) {
      return <DownloadBar sensor={sensor} />;
    } else return <div />;
  };

  const renderStopMeasurement = (sensor: ConnectedDevice) => {
    if (sensor.sensorState === sensorState.MEASURING) {
      return (
        <CardActions style={{ paddingTop: 0 }}>
          <Button
            startIcon={<BlockIcon />}
            size={"small"}
            onClick={() => {
              stopMeasurement();
            }}
          >
            Lopeta mittaus
          </Button>
        </CardActions>
      );
    } else return <div />;
  };

  let downloadInterval: any;

  let previousLength = 0;
  let stuck = 0;

  if (downloading) {
    downloadInterval = setInterval(() => {
      const currentLength = remoteGATTServerManager.downloadData.length;
      let stopDownload = false;
      if (currentLength === previousLength) {
        stuck++;
      }
      if (stuck === 5) {
        stopDownload = true;
      }
      if (!remoteGATTServerManager.bindEventListeners.length || stopDownload) {
        stuck = 0;
        previousLength = 0;
        setDownloading(false);
        clearInterval(downloadInterval);
        singleDownloadCb();
      }
      previousLength = currentLength;
    }, 500);
  }
  return (
    <Card key={sensor.id} sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 0 }}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {sensor.humanName} -{" "}
          {
            SensorNames[
              sensor.position !== undefined
                ? sensor.position
                : SensorPosition.NotSet
            ]
          }
        </Typography>
        <Typography
          variant="h5"
          component="div"
          className={sensor.connectionLost ? "font-disconnected" : "font-ok"}
        >
          {sensor.connectionLost ? "Yhteys poikki" : "Yhdistetty"}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {sensor.sensorState
            ? sensorStateString[sensor.sensorState]
            : sensorStateString[0]}
        </Typography>
        {renderDownloadProgress(sensor)}
      </CardContent>
      {renderStopMeasurement(sensor)}
      {renderDownload(sensor)}
    </Card>
  );
};
