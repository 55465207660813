import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SensorNames, SensorPosition } from "../../store/apis/sensorsApi";
import FormControl from "@mui/material/FormControl";

export const EditSensor = ({
  open,
  handleClose,
  handleEdit,
  name,
  position,
  newSensor
}: {
  open: boolean;
  handleClose: () => void;
  handleEdit: (newName: string, position: SensorPosition) => void;
  name?: string;
  position: SensorPosition;
  newSensor: boolean;
}) => {
  const [sensorName, setName] = useState(name);
  const [errorText, setErrorText] = useState("");
  const [sensorPosition, setSensorPosition] = useState(position);

  const close = () => {
    handleClose();
  };
  const handleSave = () => {
    if (sensorName && sensorName.length < 1) {
      setErrorText("Anna nimi");
    } else if (sensorName) {
      handleEdit(sensorName, sensorPosition);
      close();
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSensorPosition(parseInt(event.target.value) as SensorPosition);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        {newSensor ? "Tallenna sensori" : "Muokkaa sensoria"}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={Boolean(errorText)}
          helperText={errorText}
          autoFocus
          margin="dense"
          id="name"
          label="Nimi"
          type="text"
          fullWidth
          variant="standard"
          onChange={event => setName(event.target.value)}
          value={sensorName}
        />
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel id="demo-simple-select-label">Sijainti</InputLabel>
          <Select
            value={sensorPosition.toString()}
            onChange={handleChange}
            label="Sijainti"
          >
            <MenuItem value={SensorPosition.FrontLeft}>
              {SensorNames[SensorPosition.FrontLeft]}
            </MenuItem>
            <MenuItem value={SensorPosition.FrontRight}>
              {SensorNames[SensorPosition.FrontRight]}
            </MenuItem>
            <MenuItem value={SensorPosition.RearLeft}>
              {SensorNames[SensorPosition.RearLeft]}
            </MenuItem>
            <MenuItem value={SensorPosition.RearRight}>
              {SensorNames[SensorPosition.RearRight]}
            </MenuItem>
            <MenuItem value={SensorPosition.Neck}>
              {SensorNames[SensorPosition.Neck]}
            </MenuItem>
            <MenuItem value={SensorPosition.NotSet}>
              {SensorNames[SensorPosition.NotSet]}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Peru</Button>
        <Button onClick={handleSave}>Tallenna</Button>
      </DialogActions>
    </Dialog>
  );
};
