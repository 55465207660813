import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import {Line} from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import {useLoadAllSensorsQuery} from "../../store/";
import {SensorNames, SensorPosition} from "../../store/apis/sensorsApi";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const RenderGraph = ({id, data, position}: { id: string; data: string[]; position: Number }) => {
  let parsedData: string[][] = [];
  parsedData = data.map(d => d.split(","));

  const foundSensor = useLoadAllSensorsQuery(null, {
    selectFromResult: ({data}) => ({
      sensor: data?.find((sensor) => sensor.sensorId === id)
    }),
  });

  const accData = {
    datasets: [
      {
        label: "X",
        data: parsedData.map(d => {
          if (position === (SensorPosition.RearRight || SensorPosition.FrontRight)) {
            d[0] = (parseFloat(d[0]) * -1).toString();
          }
          return {
            x: d[6],
            y: d[0]
          };
        }),
        borderColor: "red",
        lineTension: 0.3
      },
      {
        label: "Y",
        data: parsedData.map(d => {
          return {
            x: d[6],
            y: d[1]
          };
        }),
        borderColor: "blue",
        lineTension: 0.3
      },
      {
        label: "Z",
        data: parsedData.map(d => {
          if (position === (SensorPosition.RearRight || SensorPosition.FrontRight)) {
            d[2] = (parseFloat(d[2]) * -1).toString();
          }
          return {
            x: d[6],
            y: d[2]
          };
        }),
        borderColor: "green",
        lineTension: 0.3
      }
    ]
  };

  const gyrData = {
    datasets: [
      {
        label: "X",
        data: parsedData.map(d => {
          return {
            x: d[6],
            y: d[3]
          };
        }),
        borderColor: "red",
        lineTension: 0.3
      },
      {
        label: "Y",
        data: parsedData.map(d => {
          return {
            x: d[6],
            y: d[4]
          };
        }),
        borderColor: "blue",
        lineTension: 0.3
      },
      {
        label: "Z",
        data: parsedData.map(d => {
          return {
            x: d[6],
            y: d[5]
          };
        }),
        borderColor: "green",
        lineTension: 0.3

      }
    ]
  };
  console.log(foundSensor);
  return (
    <>
      <h3>{foundSensor ? `${foundSensor.sensor?.humanName} - ${SensorNames[foundSensor.sensor && typeof (foundSensor.sensor.position) == 'number' ? foundSensor.sensor.position : 5]}` : ""}</h3>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <h4>Kiihtyvyys</h4>
          <Line data={accData}/>
        </Grid>
        <Grid item xs={6}>
          <h4>Rotaatio</h4>
          <Line data={gyrData}/>
        </Grid>
      </Grid>
    </>
  );
};
