import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../index";
import { API_BASE_URL } from "../../constants";

export enum SensorPosition {
  FrontLeft,
  FrontRight,
  RearLeft,
  RearRight,
  Neck,
  NotSet
}

export const SensorNames = [
  "Vasen etujalka",
  "Oikea etujalka",
  "Vasen takajalka",
  "Oikea takajalka",
  "Niska",
  "Ei määritetty"
];

export interface Sensor {
  id?: number;
  name: string;
  sensorId: string;
  humanName?: string;
  position: SensorPosition;
}

const sensorsApi = createApi({
  reducerPath: "sensors",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_BASE_URL}/sensors`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
    }
  }),
  tagTypes: ["loadSensors"],
  endpoints(builder) {
    return {
      loadAllSensors: builder.query<Sensor[], null>({
        providesTags: users => ["loadSensors"],
        query: () => {
          return {
            url: "/",
            method: "GET"
          };
        }
      }),
      deleteSensor: builder.mutation<Sensor, number>({
        invalidatesTags: ["loadSensors"],
        query: id => {
          return {
            url: `/${id}`,
            method: "DELETE"
          };
        }
      }),
      addSensor: builder.mutation<Sensor, Sensor>({
        invalidatesTags: ["loadSensors"],
        query: newSensor => {
          return {
            url: "/",
            method: "POST",
            body: {
              name: newSensor.name,
              sensorId: newSensor.sensorId,
              position: newSensor.position,
              humanName: newSensor.humanName
            }
          };
        }
      }),
      modifySensor: builder.mutation<Sensor, Sensor>({
        invalidatesTags: ["loadSensors"],
        query: sensor => {
          return {
            url: `/${sensor.id}`,
            method: "PATCH",
            body: {
              humanName: sensor.humanName,
              position: sensor.position
            }
          };
        }
      })
    };
  }
});

export const {
  useLoadAllSensorsQuery,
  useDeleteSensorMutation,
  useAddSensorMutation,
  useModifySensorMutation
} = sensorsApi;
export { sensorsApi };
