import * as React from "react";
import { RawData } from "../utils/RemoteGATTServerManager";
import { RenderGraph } from "./RenderGraph";
import { JSX } from "react/jsx-runtime";
import { useLoadAllSensorsQuery } from "../../store";
import { useEffect, useState } from "react";

export const RenderGraphs = ({ data }: { data?: RawData[] }) => {
  const [sensorPosition, setSensorPosition] = useState<{
    [key: string]: Number;
  }>();
  const sensors = useLoadAllSensorsQuery(null);

  useEffect(() => {
    if (sensors && sensors.data) {
      const positions: { [key: string]: Number } = {};
      sensors.data.forEach(
        sensor => (positions[sensor.sensorId] = sensor.position)
      );
      setSensorPosition(positions);
    }
  }, [sensors]);

  if (!data) return <div />;

  interface dataByDevice {
    [key: string]: string[];
  }

  const sortedData: dataByDevice = {};

  data.forEach(val => {
    if (!sortedData[val.id]) sortedData[val.id] = [];
    sortedData[val.id].push(val.value);
  });

  const keys = Object.keys(sortedData);

  let graphs: JSX.Element[] = [];

  if (keys.length) {
    if (sensorPosition) {
      keys.sort((a, b) => (sensorPosition[a] < sensorPosition[b] ? -1 : 1));
    }
    keys.forEach(key => {
      graphs.push(
        <RenderGraph
          key={key}
          id={key}
          data={sortedData[key]}
          position={sensorPosition ? sensorPosition[key] : 5}
        />
      );
    });
  }

  return <div>{graphs}</div>;

  //  return <RenderGraph id={keys[0]} data={sortedData[keys[0]]} />;
};
