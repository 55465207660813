import {
  characteristicvaluechanged,
  downloadChar,
  EOFString,
  gattserverdisconnected,
  nordicUartRxCharacteristic,
  nordicUartService,
  nordicUartTxCharacteristic,
  sensorMeasurementLengthCharacteristic,
  sensorMeasurementLengthService,
  sensorNameCharacteristic,
  sensorNameService,
  sensorStateCharacteristic,
  sensorStateService,
  startChar,
  stopChar
} from "../../constants";
import {add, remove, store} from "../../store";
import {sensorState, setMeasurementLength} from "../../store/features/connectedDeviceSlice";

export interface RawData {
  id: string;
  value: string;
}

declare global {
  interface BluetoothRemoteGATTServer {
    armId: string;
  }
}

interface BindEventListener {
  id: string;
  armId?: string;
  listener: EventListener;
}

let instance: RemoteGATTServerManager;

class RemoteGATTServerManager {
  set downloadReadyCallback(value: any) {
    this._downloadReadyCallback = value;
  }

  get downloading(): string {
    return this._downloading;
  }

  get bindEventListeners(): BindEventListener[] {
    return this._bindEventListeners;
  }

  set downloadData(value: RawData[]) {
    this._downloadData = value;
  }

  get downloadData(): RawData[] {
    return this._downloadData;
  }

  get remoteGATTServers(): BluetoothRemoteGATTServer[] {
    return this._remoteGATTServers;
  }

  private _downloadData: RawData[];
  private _remoteGATTServers: BluetoothRemoteGATTServer[];
  private _bindEventListeners: BindEventListener[];
  private _downloading: string;
  private _downloadReadyCallback: any;

  constructor() {
    if (instance) {
      throw new Error("Only one instance of this class allowed");
    }
    instance = this;
    this._downloadData = [];
    this._remoteGATTServers = [];
    this._bindEventListeners = [];
    this._downloading = "";
    this._downloadReadyCallback = undefined;
  }

  async scanDevices() {
    const device = await navigator.bluetooth.requestDevice({
      filters: [
        {
          services: [nordicUartService]
        }
      ],
      optionalServices: [sensorStateService, sensorNameService, sensorMeasurementLengthService]
    });
    if (device.gatt) {
      try {
        let server = await device.gatt.connect();
        server.device.addEventListener(gattserverdisconnected, () => {
          this.removeGATTServer(server.device.id);
          if (server.armId === this._downloading) {
            this._downloading = "";
          }
          store.dispatch(
            remove({
              id: server.armId || server.device.id,
              name: server.device.name,
              sensorState: sensorState.UNKNOWN
            })
          );
        });
        await this.readSensorState(server);
        this._remoteGATTServers.push(server);
        // +1 koska sensorilla arvo yhtä pienempi kuin frontissa
      } catch (e) {
        console.log(e);
      }
    }
  }

  public async readSensorState(server: BluetoothRemoteGATTServer) {
    try {
      const service = await server.getPrimaryService(sensorStateService);
      const char = await service.getCharacteristic(sensorStateCharacteristic);
      const val = await char.readValue();
      let name = server.device.name;
      let armId = await this.parseId(server);
      const id = armId ? armId : server.device.id;
      if (armId) server.armId = armId;
      store.dispatch(add({id: id, name, armId: id, sensorState: val.getUint8(0) + 1}));
      await this.readMeasurementLength(server);
    } catch (e) {
      console.log(e);
    }
  }

  public async readMeasurementLength(server: BluetoothRemoteGATTServer) {
    try {
      const service = await server.getPrimaryService(sensorMeasurementLengthService);
      const char = await service.getCharacteristic(sensorMeasurementLengthCharacteristic);
      const val = await char.readValue();
      let name = server.device.name;
      let armId = await this.parseId(server);
      const id = armId ? armId : server.device.id;
      if (armId) server.armId = armId;
      const byte0 = (val.getUint8(0));
      const byte1 = (val.getUint8(1));
      const byte2 = (val.getUint8(2));
      const byte3 = (val.getUint8(3));
      store.dispatch(setMeasurementLength({
        id: id,
        name,
        armId: id,
        measurementLength: byte0 + 256 * byte1 + 65536 * byte2 + 16777216 * byte3
      }));
    } catch (e) {
      console.log(e);
    }
  }

  public getDownloadedLength(id: string) {
    return this._downloadData.filter(d => d.id === id).length;
  }

  public async parseId(server: BluetoothRemoteGATTServer) {
    let id;
    try {
      const nameService = await server.getPrimaryService(sensorNameService);

      if (nameService) {
        const nameChar = await nameService.getCharacteristic(
          sensorNameCharacteristic
        );
        const nameData = await nameChar.readValue();
        if (nameData) {
          id = "";
          for (let i = 0; i < 8; i++) {
            id += nameData.getUint8(i).toString(16);
          }
        }
      }
    } catch (e) {
      id = null;
    }
    return id;
  }

  private removeGATTServer(id: string) {
    this._remoteGATTServers = this._remoteGATTServers.filter(
      server => server.device.id !== id
    );
  }

  async startMeasurement(server: BluetoothRemoteGATTServer) {
    const service = await server.getPrimaryService(nordicUartService);
    const txCharacteristic = await service.getCharacteristic(
      nordicUartTxCharacteristic
    );
    await txCharacteristic.writeValue(startChar);
  }

  async stopMeasurement(server: BluetoothRemoteGATTServer) {
    const service = await server.getPrimaryService(nordicUartService);
    const txCharacteristic = await service.getCharacteristic(
      nordicUartTxCharacteristic
    );
    await txCharacteristic.writeValue(stopChar);
    await this.readSensorState(server);
  }

  async stopNotifications(server: BluetoothRemoteGATTServer) {
    const service = await server.getPrimaryService(nordicUartService);
    const c = await service.getCharacteristic(nordicUartRxCharacteristic);
    const notificationCharacterestic = await c.stopNotifications();

    this.removeEventListener(notificationCharacterestic, server.device.id);
  }

  private removeEventListener(
    notificationCharacterestic: BluetoothRemoteGATTCharacteristic,
    id: string
  ) {
    const bindEvenListener = this._bindEventListeners.filter(e => e.id === id);
    if (bindEvenListener.length) {
      notificationCharacterestic.removeEventListener(
        characteristicvaluechanged,
        bindEvenListener[0].listener
      );
      this._bindEventListeners = this._bindEventListeners.filter(
        e => e.id !== id
      );
    }
  }

  async downloadResults(server: BluetoothRemoteGATTServer) {
    const service = await server.getPrimaryService(nordicUartService);
    const txCharacteristic = await service.getCharacteristic(
      nordicUartTxCharacteristic
    );
    await txCharacteristic.writeValue(downloadChar);
    const rxCharacteristic = await service.getCharacteristic(
      nordicUartRxCharacteristic
    );
    const notificationCharacteristic = await rxCharacteristic.startNotifications();
    const cb = this.handleDownloadDataChanged.bind(this);

    //Reference to bind eventlistener needs to be stored to be able to remove it
    this._bindEventListeners.push({
      id: server.device.id,
      armId: server.armId,
      listener: cb
    });
    notificationCharacteristic.addEventListener(characteristicvaluechanged, cb);
    await this.readSensorState(server);

  }

  async downloadSingle(id: string) {
    if (this._downloading !== "") {
      return;
    }
    this._downloading = id;
    const server = this.getGATTServer(id);
    this._downloadData = this._downloadData.filter(data => data.id !== id);
    await this.downloadResults(server);
  }

  async stopSingle(id: string) {
    const server = this.getGATTServer(id);
    this.stopMeasurement(server);
  }

  async handleDownloadDataChanged(event: Event) {
    const textDecoder = new TextDecoder();
    //@ts-ignore
    const id: string = event.target.service.device.id;
    //@ts-ignore
    const armId = event.target.service.device.gatt?.armId

    //@ts-ignore
    const value = textDecoder.decode(event.target.value);
    if (value === EOFString) {
      this._downloading = "";
      const server = this.getGATTServer(id);
      await this.stopNotifications(server);
      await this.readSensorState(server);
      this._downloadReadyCallback([...this._downloadData]);
    } else {
      this._downloadData.push({id: armId || id, value});
    }
  }

  getGATTServer(id: string): BluetoothRemoteGATTServer {
    const found = this._remoteGATTServers.filter(
      server => server.device.id === id || server.armId === id
    );
    if (found) {
      return found[0];
    } else {
      throw new Error("GATTServer not found");
    }
  }

  getGATTServerByArmId(id: string): BluetoothRemoteGATTServer {
    const found = this._remoteGATTServers.filter(
      server => server.armId === id
    );
    if (found) {
      return found[0];
    } else {
      throw new Error("GATTServer not found");
    }
  }

  public async stopDownload(id: string) {
    const server = this.getGATTServerByArmId(id);
    await this.stopNotifications(server);
    await this.readSensorState(server);
  }

}

let remoteGATTServerManager = new RemoteGATTServerManager();

export default remoteGATTServerManager;
