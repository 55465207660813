import * as React from "react";
import { useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import { Sensor, SensorPosition } from "../../store/apis/sensorsApi";
import { EditSensor } from "./EditSensor";

export const SensorRow = ({
  server,
  savedSensors,
  addSensor
}: {
  server: BluetoothRemoteGATTServer;
  savedSensors: Sensor[];
  addSensor: (
    name: string,
    sensorId: string,
    position: SensorPosition,
    humanName: string
  ) => void;
}) => {
  const [open, setOpen] = useState(false);

  const found = savedSensors.some(
    sensor => sensor.sensorId === (server.armId || server.device.id)
  );
  const { name, id } = server.device;

  const handleAdd = (humanName: string, position: SensorPosition) => {
    addSensor(name || "", server.armId || id, position, humanName);
  };

  return (
    <TableRow>
      <EditSensor
        open={open}
        handleClose={() => setOpen(false)}
        handleEdit={handleAdd}
        name=""
        position={SensorPosition.NotSet}
        newSensor={true}
      />
      <TableCell>{server.armId || id}</TableCell>
      <TableCell align="right">
        {!found && (
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={() => setOpen(true)}
          >
            Tallenna
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
